import { combineReducers } from 'redux'
import auth from './auth'
import actions from './actions'
import userActions from './userActions'
import users from './users'

export default combineReducers({
  auth,
  actions,
  userActions,
  users,
})
