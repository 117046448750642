export const ACTION_LOGIN = 'login'
export const ACTION_LOGOUT = 'logout'

const initialState = {
  errors: null,
  accessToken: null,
}

const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_LOGIN:
      return { ...state, accessToken: payload.access_token, errors: payload.errors }
    default:
      return state
  }
}

export default auth
