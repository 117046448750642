import { ACTION_UPDATE_USER } from './users'

export const ACTION_LOAD_ACTIONS = 'load_actions'
export const ACTION_UPDATE_ACTION = 'update_action'
export const ACTION_DELETE_ACTION = 'delete_action'
export const ACTION_DISPLAY_ACTION_MODAL = 'display_action_modal'
export const ACTION_CHANGE_ACTION_FORM = 'change_action_form'

const initialState = {
  needLoad: true,
  items: [],
  settings: {
    repeat: [],
    period: [],
    structure: [],
    format: [],
    type: [],
    columns: [],
  },
  temp: [],
  errors: {},
  visible: false,
  selectedAction: {
    repeat: 1,
    format: 1,
    type: 1,
    period: 1,
    structure: 1,
    schedule: '* * * * *',
  },
}

const actions = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_LOAD_ACTIONS:
      return {
        ...state,
        items: payload.items,
        settings: payload.settings,
        needLoad: false,
      }
    case ACTION_UPDATE_ACTION:
      return {
        ...state,
        needLoad: true,
        visible: false,
        selectedAction: {
          repeat: 1,
          format: 1,
          type: 1,
          period: 1,
          structure: 1,
          schedule: '* * * * *',
        },
      }
    case ACTION_DELETE_ACTION:
      return {
        ...state,
        needLoad: true,
        visible: false,
        selectedAction: {
          repeat: 1,
          format: 1,
          type: 1,
          period: 1,
          structure: 1,
          schedule: '* * * * *',
        },
      }
    case ACTION_DISPLAY_ACTION_MODAL:
      return {
        ...state,
        visible: payload.visible,
        selectedAction: payload.selectedAction,
      }
    case ACTION_CHANGE_ACTION_FORM:
      const { field, value } = payload

      const newSelectedAction = Object.assign({}, state.selectedAction)
      newSelectedAction[field] = value
      return {
        ...state,
        selectedAction: newSelectedAction,
      }
    case ACTION_UPDATE_USER:
      return {
        ...state,
        needLoad: true,
        items: [],
      }
    default:
      return state
  }
}

export default actions
