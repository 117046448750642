import cookie from 'cookie'
import agent from '../http/agent'

const Auth = {
  isAuthenticated: false,
  authToken: null,
  cookie: null,
  authenticate(token, cb) {
    this.authToken = token
    this.setTokenInCookie(token)
    this.isAuthenticated = true
    agent.setToken(token)
    setTimeout(cb, 100) // fake async
  },
  signout(cb) {
    agent.setToken(null)
    this.isAuthenticated = false
    this.authToken = null
    localStorage.removeItem('token')
    this.dropTokenFromCookie()
    setTimeout(cb, 100) // fake async
  },
  setTokenInCookie(token) {
    const cookieToken = cookie.serialize('authToken', token, { maxAge: 2 * 24 * 60 * 60 })
    window.document.cookie = `${cookieToken}; path=/`
  },
  dropTokenFromCookie() {
    const cookieToken = cookie.serialize('authToken', null, { maxAge: -1 })
    window.document.cookie = `${cookieToken}; path=/`
  },
  getTokenFromCookie() {
    const cookieToken = cookie.parse(document.cookie)
    if (cookieToken.authToken) {
      this.cookie = cookieToken.authToken
      return cookieToken.authToken
    } else {
      return null
    }
  },
  hasTokenInCookie() {
    if (this.cookie) {
      return true
    } else {
      const cookieToken = this.getTokenFromCookie()
      if (cookieToken) {
        this.cookie = cookieToken
        return true
      } else {
        return false
      }
    }
  },
  /**
   * проверяем залогинен ли пользователь
   * Достаем токен из local storage
   */
  init() {
    const cookieToken = this.getTokenFromCookie()
    if (!this.isAuthenticated && cookieToken) {
      Auth.authenticate(cookieToken)
    }
  },
}

Auth.init()

export default Auth
