import { ACTION_UPDATE_ACTION } from './actions'
import { ACTION_UPDATE_USER } from './users'

export const ACTION_LOAD_USER_ACTIONS = 'load_user_actions'
export const ACTION_UPDATE_USER_ACTION = 'update_user_action'

const initialState = {
  needLoad: true,
  userActions: [],
  userActionColumns: [],
  errors: {},
}

const actions = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_LOAD_USER_ACTIONS:
      return {
        ...state,
        userActions: payload.items ?? [],
        userActionColumns: payload.columns ?? [],
        needLoad: false,
      }
    case ACTION_UPDATE_USER_ACTION:
      return {
        ...state,
        userActions: payload.items,
      }
    case ACTION_UPDATE_ACTION:
    case ACTION_UPDATE_USER:
      return {
        ...state,
        needLoad: true,
        userActions: [],
      }
    default:
      return state
  }
}

export default actions
