import Auth from './services/auth/Auth'
import { ACTION_LOGIN, ACTION_LOGOUT } from './reducers/auth'

const localStorageMiddleware = () => (next) => (action) => {
  if (action.type === ACTION_LOGIN) {
    if (action?.payload?.access_token) {
      Auth.authenticate(action.payload.access_token, () => {
        window.location.href = '/'
      })
    }
  } else if (action.type === ACTION_LOGOUT) {
    Auth.signout(() => {
      window.location.href = '/login'
    })
  }

  next(action)
}

export { localStorageMiddleware }
