export const ACTION_LOAD_USERS = 'action_load_users'
export const ACTION_UPDATE_USER = 'action_update_user'

const initialState = {
  needLoad: true,
  users: [],
  usersColumns: [],
  teams: [],
  errors: {},
}

const actions = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_LOAD_USERS:
      return {
        ...state,
        users: payload.users ?? [],
        usersColumns: payload.columns ?? [],
        teams: payload.teams ?? [],
        needLoad: false,
      }
    case ACTION_UPDATE_USER:
      return {
        ...state,
        users: payload.users ?? [],
      }
    default:
      return state
  }
}

export default actions
