let token = null

// const createUrl = (path) => `https://rider.dp.ua/api/${path}`
const createUrl = (path) => `${process.env.REACT_APP_API_DOMAIN}/api/${path}`

const httpRequest = async (method, path, params) => {
  const response = await fetch(createUrl(path), {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(params),
  })

  return response.json()
}

const Auth = {
  login: ({ email, password }) => httpRequest('post', 'login', { email, password }),
}

const UserActions = {
  initLoad: () => httpRequest('get', 'users/actions'),
  update: ({ userId, action, value }) =>
    httpRequest('put', `users/actions/${userId}/update`, { action, value }),
}

const Users = {
  initLoad: () => httpRequest('get', 'users'),
  update: ({ userId, field, value }) =>
    httpRequest('put', `users/${userId}/update`, { field, value }),
}

const Actions = {
  index: () => httpRequest('get', 'actions'),
  create: (params) => httpRequest('post', 'actions', params),
  update: (params) => httpRequest('put', `actions/${params.id}`, params),
  delete: (actionId) => httpRequest('delete', `actions/${actionId}`),
}

const Agent = {
  Auth,
  UserActions,
  Actions,
  Users,
  setToken: (_token) => {
    token = _token
  },
}

export default Agent
